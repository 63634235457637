var prodcat = prodcat || '';
var site = site || '';
var MantleReviews = MantleReviews || {};
var MantleBehavior = MantleBehavior || {};

(function ($) {
  /**
   * Now that stars are rendered lazily. No need for the timeout.
   *
   * TODO: Once API version is the only version. Need to remove these options.
   */
  MantleReviews.disableTimeout = true;

  Drupal.behaviors.productRatingV1 = MantleBehavior.create({
    targetSelector: '.js-product-rating',
    snippetIndex: 0,

    // eslint-disable-next-line complexity
    attachTarget: function (target) {
      if (!window.MantleReviews || _.isUndefined(Drupal.settings.power_reviews)) {
        return;
      }

      // Bail if Power Reviews not set up.
      if (!MantleReviews.power_reviews_enabled()) {
        return;
      }

      var $template = $(target);
      var productContext = prodcat.ui.getProductContext($template);

      if (!productContext) {
        /* eslint-disable-next-line no-console */
        console.warn('Could not determine which product applied to ratings');

        return;
      }

      var productId = productContext.product_id;
      var product = prodcat.data.getProduct(productId);
      var productBaseId = product.PROD_BASE_ID;
      var pageId = Drupal.settings.power_reviews.page_id || productBaseId;
      var reviewSnippetId =
        'pr-reviewsnippet-' + productBaseId + '-' + new Date().getTime() + this.snippetIndex++;
      var $reviewSnippet = $('.review-snippet', $template);

      // need review-snipet
      if (!$reviewSnippet.length) {
        return;
      }

      // setup dom
      $reviewSnippet.attr('id', reviewSnippetId);
      $reviewSnippet.attr('data-product-id', productId);
      $template.data('page-id', pageId);

      // attach the CategorySnippet
      var prProduct = MantleReviews.get_base_pr_product();

      prProduct.page_id = pageId;
      prProduct.product_base_id = productBaseId;
      prProduct.disableSyncSnippetRender = true;

      // add the category snippet
      prProduct.components.CategorySnippet = $reviewSnippet.get(0);
      MantleReviews.add_pr_product(prProduct);
    },

    /*
     * Lazily Render the review stars.
     */
    attachLazyTarget: function (target) {
      // Bail if Power Reviews not set up.
      if (!MantleReviews.power_reviews_enabled()) {
        return;
      }

      var $template = $(target);
      var $parent = $template.parent();
      var prRenderStyle = 'snippet';

      if ($parent.data('pr-render-style')) {
        prRenderStyle = $parent.data('pr-render-style');
      }

      if ($template.data('pr-render-style')) {
        prRenderStyle = $template.data('pr-render-style');
      }

      if (prRenderStyle === 'snippet') {
        this.renderSnippet($template);
      }

      if (prRenderStyle === 'dom-attrs') {
        this.renderDomAttrs($template);
      }
    },

    /*
     * Search for data-pr-render-attr attributes which signify dom elements
     * that get special processing.
     * Example:
     *   <div class="review-snippet-stars" data-pr-render-attr="stars">
     *   </div>
     *   <a
     *     class="review-snippet-count"
     *     href="#"
     *     data-pr-render-attr="mustache-template"
     *     data-pr-template="Count: {{ review_count }}"></a>
     *
     */
    renderDomAttrs: function ($template) {
      var self = this;
      var pageId = $template.data('page-id');
      var subject = MantleReviews.getData(pageId);

      subject.subscribe(function (snippetRenderItem) {
        var rollup = snippetRenderItem.rollup;
        var record = snippetRenderItem.record;
        // Do some munging here to rollup. rollup is treated like our variables
        // array.
        var fullWriteUrl = MantleReviews.generateFullWriteReviewUrlFromPRProduct(record.pr_product);

        rollup.has_reviews = rollup.review_count > 0;
        rollup.has_single_review = rollup.review_count === 1;
        rollup.write_review_url = fullWriteUrl;

        // We attach state attrs so that we can do hiding/showing via css.
        // For now this is specifically to toggle the read reviews / write a
        // review links.
        var $attachStateTargets = $('[data-pr-attach-state]', $template);

        // By default the template will get the state attrs. This should
        // normally be enough.
        $attachStateTargets = $attachStateTargets.add($template);
        $attachStateTargets.each(function (i, obj) {
          var $attachStateTarget = $(obj);

          if (rollup.has_reviews) {
            $attachStateTarget.attr('data-pr-has-reviews', 1);
          } else {
            $attachStateTarget.removeAttr('data-pr-has-reviews');
          }
        });

        // Attach the proper href to write review links. This is normally
        // handed by the POWERREVIEW Review Snippet render.
        var $writeUrlTargets = $('[data-pr-attach-write-url]', $template);

        $writeUrlTargets.each(function (i, obj) {
          var $writeUrlTarget = $(obj);

          $writeUrlTarget.attr('href', fullWriteUrl);
        });

        // Now we render the individual render styles.
        var $domRenderTargets = $('[data-pr-render-attr]', $template);

        $domRenderTargets.each(function (i, obj) {
          var $dom = $(obj);
          var renderAttr = $dom.data('pr-render-attr');

          self.renderDomAttrsContent($dom, renderAttr, rollup);
        });

        $template.addClass('pr-loaded');
      });
    },

    /*
     * Handles data-pr-render-attr. This deals specifically with changing the
     * content of the element.
     */
    renderDomAttrsContent: function ($dom, renderAttr, rollup) {
      if (!renderAttr) {
        return;
      }

      if (renderAttr === 'stars') {
        var renderedStars = MantleReviews.renderStars(rollup);

        $dom.html(renderedStars);

        return;
      }

      if (renderAttr === 'mustache-template') {
        var mustacheTemplate = $dom.data('pr-template');

        if (!mustacheTemplate) {
          return;
        }
        var rendered = Mustache.render(mustacheTemplate, rollup);

        $dom.html(rendered);

        return;
      }

      // Match any attrs in the rollup object;
      if (renderAttr in rollup) {
        $dom.html(rollup[renderAttr]);

        return;
      }
    },

    /*
     * Renders the generated stars.
     */
    renderSnippet: function ($template) {
      var $reviewSnippet = $('.review-snippet', $template);
      var $reviewCount = $('.review-count', $template);
      var pageId = $template.data('page-id');
      var subject = MantleReviews.getData(pageId);

      subject.subscribe(function (snippetRenderItem) {
        var rollup = snippetRenderItem.rollup;
        var renderedStars = MantleReviews.renderStars(rollup);

        $reviewSnippet.html(renderedStars);
        $reviewCount.html('(' + rollup.review_count + ')');
      });
    }
  });
})(jQuery);
